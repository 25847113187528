import { MaybeComputedRef } from '@vueuse/core';
import { MetaObject } from 'nuxt/schema';

const metaTitle = ref();
const metaTag = ref();
const metaLink = ref();

export const useSeoMatic = (refSeo: MaybeComputedRef<object>) => {
    const seo = unref(refSeo) as {
        metaTitleContainer: string;
        metaTagContainer: string;
        metaLinkContainer: string;
    };

    if (!seo || typeof seo === 'undefined') {
        return undefined;
    }

    if (seo.metaTitleContainer && typeof seo.metaTitleContainer !== 'undefined') {
        metaTitle.value = JSON.parse(seo.metaTitleContainer);
    }
    if (seo.metaTagContainer && typeof seo.metaTagContainer !== 'undefined') {
        metaTag.value = JSON.parse(seo.metaTagContainer);
    }
    if (seo.metaLinkContainer && typeof seo.metaLinkContainer !== 'undefined') {
        metaLink.value = JSON.parse(seo.metaLinkContainer);
    }

    const metaData : MaybeComputedRef<MetaObject> = ref({});

    if (metaTitle.value?.title) {
        metaData.value.title = metaTitle.value?.title?.title;
    }

    if (metaLink.value?.canonical) {
        metaData.value.link = [
            metaLink.value.canonical,
            // remove the by nuxt-i18n added canonical url. (otherwise there will be two)
            { hid: 'i18n-can', rel: '', href: '' }
        ];
    }

    metaData.value.meta = [
        getDescription(),
        ...getMetaFields('og:'),
        ...getMetaFields('twitter:'),
        getRobots()
    ];

    useHead(metaData);
};

const getDescription = () => {
    if (metaTag.value?.description) {
        return { hid: 'description', ...metaTag.value?.description };
    }

    return {};
};

/**
 * Get metadata by a specific prefix, e.g. `twitter:` / `og:`
 */
const getMetaFields = (prefix: string) => {
    if (!metaTag.value) {
        return [];
    }

    return Object.keys(metaTag.value)
        // filter by given prefix
        .filter(key => key.indexOf(prefix) === 0)
        // then map over filtered results
        .map((key) => {
            return { hid: key, name: key, content: metaTag.value?.[key]?.content };
        });
};

const getRobots = () => {
    if (metaTag.value?.robots) {
        return { hid: 'robots', ...metaTag.value?.robots };
    }

    return {};
};
