import { useSeoMatic } from '~/composables/useSeoMatic';
import { createError } from '#imports';
import { usePageStore } from '~/store/pages';

export const useCraftEntrySetup = (data) => {
    const pages = usePageStore();

    if (!data.value.data.entry) {
        throw createError({
            statusCode: 404,
            statusMessage: 'Page not found'
        });
    }

    const entry = data?.value?.data?.entry;

    pages.setCurrentPageTitle({ title: entry?.title });
    useSeoMatic(entry?.seomatic);

    return entry;
};
